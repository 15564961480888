'use strict';

angular.module("aerosApp")
	.factory('viewEfiImageModal', ["$uibModal", function ($uibModal) {

		function open(efiResult, fiberGroup, fiberId, endName, pairMode, metadata, standard) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/dashboard/project/modal/viewEfiImageModal.html",
				controller: "ViewEfiImageModal",
				resolve: {
					efiResult: function() { return efiResult; },
					fiberGroup: function() { return fiberGroup; },
					fiberId: function() { return fiberId; },
					endName: function() { return endName; },
					pairMode: function () { return pairMode; },
					metadata: function () { return metadata; },
                    standard: function () { return standard; }
				}
			});
			return modal.result;
		}

		return {
			open: open
		};
	}])
	.controller("ViewEfiImageModal", [ "$scope", "$uibModalInstance", "efiResult", "fiberGroup", "fiberId", "endName", "pairMode", "metadata", "standard", function($scope, $uibModalInstance, efiResult, fiberGroup, fiberId, endName, pairMode, metadata, standard) {
		$scope.efiResult = efiResult;
		$scope.fiberGroup = fiberGroup;
		$scope.fiberId = fiberId;
		$scope.endName = endName;
		$scope.pairMode = pairMode || "None";
        $scope.standard = standard ;

		//$scope.metadata = metadata;

		// translate data contract
		var t = {
				"P": "PassedOrComplete",
				"F": "Failed",
				"S": "scratches",
				"D": "defects",
				"< ": "<",
				"<=": "≤",
				"- ": "-",
				">=": "≥",
				"> ": ">"
			};
		_.each(metadata, function (n) {
		    n.type =  t[n.contaminationType];
		    n.allow = n.requirement;
		    n.lowRange = n.sizeIntervalStart;
		    n.highRange = n.sizeIntervalEnd;
		    n.found = n.numberFound;
		    n.status = t[n.result.charAt(0)];
		    n.operator = t[n.operator];
		    n.unit = "µm";
		});
			
		 // group flat collection by zone  (A-D) and type (scratches, defects)
		var m2={};
		_.each(_.groupBy(metadata, "zone"), function (o,z) {
			m2[z] = _.groupBy(o, "type");
		});
		// find max # rows needed (one row can fit 1 scratch + 1 defect) – as array length for ng-repeat loop
		_.each(m2, function (m) {
			m.max = new Array( _.max([_.size(m.scratches),_.size(m.defects)]) );
		});
		$scope.metadata = m2;
		
		$scope.parseLimit = function (limit) {
			if (limit.operator==="-") {
				// TODO: is this format correct? Are there others?
				return limit.lowRange + limit.operator + limit.highRange + limit.unit + "≤" + limit.allow;			
				//return limit.sizeIntervalStart + limit.operator + limit.sizeIntervalEnd + limit.unit + "<" + limit.requirement;
			} else {
				return limit.allow + limit.operator + limit.highRange + limit.unit;
				//return limit.requirement + limit.operator + limit.sizeIntervalEnd + limit.unit;
			}
		};
		$scope.getStatus = function (typeArr, i) {
			if (typeArr && typeArr[i]) {
				return typeArr[i].status;
				//return typeArr[i].result;
			} else {
				return '';
			}
		};
		$scope.resultIcons = {
			"PassedOrComplete": "check",
			"Failed": "times",
			"Incomplete": "question"
		};
	
		
		$scope.close = function() {
			$uibModalInstance.dismiss();
		};
	
	}]);

